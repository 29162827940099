import React from 'react'
import { Result, Spin, Table, Typography  } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Chart from "react-apexcharts"

const { Title } = Typography


const Statistics = ({ showMessage, rsSkillsGroup, statistics }) => {

  function dataStatus() {
    if (

      statistics === null &&
      rsSkillsGroup === null
    ) {
      return "loading"
    } else if (
      typeof statistics === 'object' && statistics !== null &&
      Array.isArray(rsSkillsGroup)
    ) {
      return "ok"
    } else {
      return "error"
    }
  }

  const colors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']

  const topSkillsColumns = [
    // {
    //   title: 'Rank',
    //   dataIndex: 'rank',
    //   key: 'rank',
    //   width: "60px"
    // },
    {
      title: 'Skill Group',
      dataIndex: 'skillId',
      key: 'skillId',
      render: value => rsSkillsGroup.find(e => e.id === value).name
    },
    {
      title: 'Occurrence',
      dataIndex: 'percentage',
      key: 'percentage',
      width: "90px",
      align: "right",
      render: value => <>{value} %</>
    }
  ]

  return (
    <section className="statistics">
      <span><Title className="pageTitle">Statistics</Title></span>
      <div className={statistics ? " " : " vertCenter"}>
        {
          dataStatus() === "loading" ? (
            <Spin tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : dataStatus() === "error" ? (
            <Result
              status="500"
              title="500"
              subTitle="Sorry, something went wrong."
            />
          ) : (
            <>
              <Table 
                size="small" 
                pagination={false} 
                columns={topSkillsColumns} 
                dataSource={statistics.top_skills} 
                className="topSkillsTable" 
                title={() => 'List of the 10 most sought-after skills (for medium dataset)'} 
                rowKey="rank"
              />

              <div className="chart-container">
                <Chart
                  type="radar"
                  series={[{
                    name: "Skill occurence",
                    data: statistics.radar_plot ? statistics.radar_plot.map(e => e.occurrence) : []
                  }]}
                  options= {{
                    colors: [colors[0]],
                    chart: {
                      toolbar: {
                        show: false
                      },
                    },
                    xaxis: {
                      categories: statistics.radar_plot ? statistics.radar_plot.map(i => rsSkillsGroup.find(e => e.id === i.skillId).name) : []
                    },
                  }}
                />
              </div>
            </>
          )
        }
      </div>
    </section>
  )
}

export default Statistics
