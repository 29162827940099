import React from 'react'
import Axios from 'axios'
import { Divider, Result, Spin, Table, Typography, Row, Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'

import {Config} from './config_section.js'

const { Title } = Typography


const VisitorsStatistics = ({ showMessage, authData, usersData }) => {
  const [visitorsStats, setVisitorsStats] = React.useState(null)
  const [visitorsFeedback, setVisitorsFeedback] = React.useState(null)
  /*
  stavy dat:
    init loading = null
    chyba pri loadingu = 0
    prazdne data = []
    plne data = [data]
  */

  React.useEffect(() => {
    let isMounted = true
    Axios.post( Config.server.getVisitorsStats, authData, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        if (isMounted) {
          if (response && response.data.error === false){
            setVisitorsStats(response.data.data.map(obj=> (
              { ...obj, difference: obj.end_time !== null ? moment(obj.end_time).diff(moment(obj.start_time)) : null, key: obj.id}
            )))
            let sectionCounts = {}
            response.data.feedback_section.forEach((item) => {
              sectionCounts[item] = sectionCounts[item] ? sectionCounts[item] + 1 : 1
            })
            if (Object.keys(sectionCounts).includes("")) {
              // sectionCounts['Without an Answer'] = sectionCounts['']
              delete sectionCounts['']
            }
            setVisitorsFeedback({
              main: {
                yes: response.data.feedback_main.filter(item => item === 'useful').length,
                no: response.data.feedback_main.filter(item => item === 'useless').length,
                unknown: response.data.feedback_main.filter(item => item === '').length
              },
              section: sectionCounts
            })
          } else {
            console.log(response.data.message)
            setVisitorsStats(0)
            setVisitorsFeedback(0)
          }
        }
      })
      .catch((error) => {
        console.log("Server is unavailable")
        console.log(error)
        setVisitorsStats(0)
        setVisitorsFeedback(0 )
      })

    return () => { isMounted = false }
  }, [authData])

  // Some section names are too long to be displayed normally, so they're abbreviated
  const sectionAbbreviations = {
    'admin-visitors-stats'            : 'Admin Visitor Stats',
    'admin-profiles'                  : 'Admin Profiles',
    'admin-skills'                    : 'Admin Skills Group',
    'admin-users'                     : 'Admin User Manager',
    'admin-certs'                     : 'Admin Certs',
    'admin-trainings'                 : 'Admin Trainings',
    'admin-study-programs'            : 'Admin Study Programs',
    'csprofiler-certs'                : 'CSP Certs',
    'csprofiler-trainings'            : 'CSP Trainings',
    'csprofiler-statistics'           : 'CSP Statistics',
    'csprofiler-curricula'            : 'CSP Curricula',
    'csprofiler-study-profiler'       : 'CSP Study Profiler',
    'job-ads-analyzer-statistics'     : 'JAA Statistics',
    'job-ads-analyzer'                : 'Job Ads Analyzer',
    'job-ads-analyzer-create-your-ad' : 'JAA Create Ad',
    'roles'                           : 'ECSF Roles',
    'career-path'                     : 'Career Path',
  }

  const getMostFrequentCountry = (input) => {
    const elementCount = {}
    let maxElement = null
    let maxCount = 0
    input.forEach(obj => {
      const value = obj.country;
      elementCount[value] = (elementCount[value] || 0) + 1;
      if (elementCount[value] > maxCount) {
        maxElement = value;
        maxCount = elementCount[value];
      }
    })
    return maxElement
  }

  const getMostVisitedSection = (input) => {
    const nameCount = {}
    input.forEach(obj => {
      obj.sections.forEach(name => {
        if (name !== 'home') nameCount[name] = (nameCount[name] || 0) + 1
      });
    });
    let maxName = null
    let maxCount = 0

    Object.entries(nameCount).forEach(([name, count]) => {
      if (count > maxCount) {
        maxName = sectionAbbreviations[name] || name
        maxCount = count
      }
    })
    return maxName
  }

  const getAvarageDuration = (input) => {
    const filteredVisitors = input.filter(obj => obj.difference !== null)
    return printDuration(moment.duration(filteredVisitors.reduce((accumulator, object) => {
      return accumulator + object.difference;
    }, 0) / filteredVisitors.length))
  }

  const getDevicePlot = (input) => {
    const deviceCounts = input.reduce((counts, item) => {
      counts[item.device] = (counts[item.device] || 0) + 1
      return counts
    }, {})
    return Object.keys(deviceCounts).map(device => ({
      device,
      occurrences: deviceCounts[device]
    }))
  }

  const getCountryPlot = (input) => {
    const countryCounts = input.reduce((counts, item) => {
      counts[item.country] = (counts[item.country] || 0) + 1
      return counts
    }, {})

    const sortedCountries = Object.keys(countryCounts).map(country => ({
      country,
      occurrences: countryCounts[country]
    })).sort((a, b) => b.occurrences - a.occurrences);

    return sortedCountries;
  }

  const getProcessedData = (visitorsStats) => {
    const sortedData = getCountryPlot(visitorsStats);

    const top15 = sortedData.slice(0, 15);
    const others = sortedData.slice(15);

    const otherCount = others.reduce((acc, country) => acc + country.occurrences, 0);

    const processedData = [...top15, { country: 'Others', occurrences: otherCount }];

    return processedData;
  };

  const getVisitedSectionsPlot = (input) => {

    const sectionCount = {}
    input.forEach(obj => {
      obj.sections.forEach(name => {
        if (name !== 'home') sectionCount[name] = (sectionCount[name] || 0) + 1
      });
    });

    const sortedSections = Object.keys(sectionCount).map(section => ({
      section,
      abbreviatedSection: sectionAbbreviations[section] || section,
      occurrences: sectionCount[section]
    })).sort((a, b) => b.occurrences - a.occurrences);

    return sortedSections;
  }

  const getOperatingSystemPlot = (input) => {
    const operatingSystemsCount = input.reduce((counts, operatingSystems) => {
      counts[operatingSystems.os.name] = (counts[operatingSystems.os.name] || 0) + 1
      return counts
    }, {})
    return Object.keys(operatingSystemsCount).map(name => ({
      name,
      occurrences: operatingSystemsCount[name]
    }))
  }

  const getVisitDurationPlot = (input) => {
    const filteredVisitDurations = input.filter(obj => obj.difference !== null)

    const timeClassesCount = {
      'less than 10s' : 0,
      'less than 1m'  : 0,
      'less than 10m' : 0,
      'less than 1h'  : 0,
      'less than 2h'  : 0,
      'more than 2h'  : 0
    };

    filteredVisitDurations.forEach(obj => {
      const durationInSeconds = moment.duration(obj.difference).asSeconds();
  
      if (durationInSeconds > 7200) {
        timeClassesCount['more than 2h'] += 1;
      } else if (durationInSeconds > 3600) {
        timeClassesCount['less than 2h'] += 1;
      } else if (durationInSeconds > 600) {
        timeClassesCount['less than 1h'] += 1;
      } else if (durationInSeconds > 60) {
        timeClassesCount['less than 10m'] += 1;
      } else if (durationInSeconds > 10){
        timeClassesCount['less than 1m'] += 1;
      } else {
        timeClassesCount['less than 10s'] += 1;
      }
    });

    return Object.keys(timeClassesCount).map(name => ({
      name,
      occurrences: timeClassesCount[name]
    }));
  }

  const getUserUsagePlot = (input) => {
    const visitationCounts = input.reduce((counts, obj) => {
      if (obj.name != null) {
        counts[obj.name] = (counts[obj.name] || 0) + 1;
      }
      return counts;
    }, {});

    return Object.keys(visitationCounts).map(name => ({
      name,
      occurrences: visitationCounts[name]
    }));
  }

  function printDuration(input) {
    const hours = Math.floor(input.asHours())
    const minutes = input.minutes()
    const seconds = input.seconds()
    let output = hours !== 0 ? hours + " hours, " : ""
    output += minutes !== 0 ? minutes + " minutes, " : ""
    output += seconds + " seconds"
    return output
  }

  const allUsers = [...usersData.newUsers, ...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers]

  return (
    <div id="visitors-statistics" >
      <span><Title className="pageTitle">Visitors Statistics</Title></span>
      {
        visitorsStats === null ? (
          <div className="vertCenter"><Spin tip="Loading..." indicator={<LoadingOutlined setstyle={{ fontSize: 24 }} spin />} /></div>
        ) : visitorsStats === 0 || !Array.isArray(visitorsStats)? (
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
          />
        ) : (<>

          <Divider><Title level={4}>Summary</Title></Divider>

          <Table size="small" showHeader={false} pagination={false} className={"table-summary"} dataSource={[
            {
              key: 1,
              param: "Total Number of Visitors",
              value: visitorsStats.length + " (from " + visitorsStats[0].start_time+ " [CET])"
            },
            {
              key: 2,
              param: "Avarage Visit Duration",
              value: getAvarageDuration(visitorsStats)
            },
            {
              key: 3,
              param: "Most Visitors Are From",
              value: getMostFrequentCountry(visitorsStats)
            },
            {
              key: 4,
              param: "Most Visited Section",
              value: getMostVisitedSection(visitorsStats)
            }
          ]}
          columns={[
            {
              dataIndex: 'param',
              key: 'param',
              align: 'right'
            },
            {
              dataIndex: 'value',
              key: 'value',
              align: 'left'
            }
          ]}
          />

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Divider><Title level={4}>Visitors' Device Type</Title></Divider>

              <div className="chart-container">
                <ReactApexChart
                  type="donut"
                  series={getDevicePlot(visitorsStats).map(e => e.occurrences)}
                  options={{
                    labels: getDevicePlot(visitorsStats).map(e => e.device),
                  }}
                />
              </div>
            </Col>

            <Col span={8}>
              <Divider><Title level={4}>Visitors' Operating System</Title></Divider>

              <div className="chart-container">
                <ReactApexChart
                  type="donut"
                  series={getOperatingSystemPlot(visitorsStats).map(e => e.occurrences)}
                  options={{
                   labels: getOperatingSystemPlot(visitorsStats).map(e => e.name === '' ? 'Unknown' : e.name),
                   colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0', '#FF00FF']
                  }}
                />
              </div>
            </Col>

            <Col span={8}>
              <Divider><Title level={4}>Duration of Visits</Title></Divider>

                <div className="chart-container">
                  <ReactApexChart
                    type="donut"
                    series={getVisitDurationPlot(visitorsStats).map(e => e.occurrences)}
                    options={{
                     labels: getVisitDurationPlot(visitorsStats).map(e => e.name),
                     colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0', '#FF00FF'],
                    }}
                  />
                </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>

            <Col span={8}>
              <Divider><Title level={4}>Registered User Visits</Title></Divider>

              <div className="chart-container">
                <ReactApexChart
                  type="bar"
                  series={[
                    {
                      name: 'User visits',
                      data: getUserUsagePlot(visitorsStats).map(e => e.occurrences)
                    }
                  ]}
                  options={{
                    colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0', '#FF00FF'],
                    xaxis: {
                      categories: getUserUsagePlot(visitorsStats).map(e => e.name),
                      position: 'bottom',
                    },
                    chart: {
                      type: 'bar',
                      height: 350,
                      toolbar: {
                        show: false,
                      },
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 4,
                        horizontal: true,
                        distributed: true,
                      },
                    },
                      dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                    },
                  }}
                />
              </div>
            </Col>

            <Col span={8}>

              <Divider><Title level={4}>Visited Sections</Title></Divider>
              <div className="chart-container">
                <ReactApexChart
                  type="bar"
                  series={[{
                    name: 'Visits',
                    data: getVisitedSectionsPlot(visitorsStats).map(e => e.occurrences)
                  }]}
                  options={{
                    colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0', '#FF00FF'],
                    chart: {
                      type: 'bar',
                      height: 350,
                      toolbar: {
                        show: false,
                      },
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 4,
                        horizontal: true,
                        distributed: true,
                      }
                    },
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: false,
                    },
                    xaxis: {
                      categories: getVisitedSectionsPlot(visitorsStats).map(e => e.abbreviatedSection),
                    }
                  }}
                />
              </div>
            </Col>

            <Col span={8}>
              <Divider><Title level={4}>Visitors' Country</Title></Divider>

              <div className="chart-container">
                <ReactApexChart
                  type="bar"
                  series={[{
                    name: 'Visitors',
                    data: getProcessedData(visitorsStats).map(e => e.occurrences)}]}
                  options={{
                    labels: getProcessedData(visitorsStats).map(e => e.country),
                    xaxis: {
                      categories: getProcessedData(visitorsStats).map(e => e.country),
                      position: 'bottom',
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 4,
                        horizontal: true,
                        distributed: true,
                      }},
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                    },
                    chart: {
                      toolbar: {
                        show: false,
                      },
                    },
                  }}
                />
              </div>
            </Col>
          </Row>

          

          <Row gutter={[16, 16]}>

            <Col span={4}></Col>
            <Col span={8}>

              <Divider><Title level={4}>Visitors' Satisfaction with the App</Title></Divider>

              {visitorsFeedback && typeof visitorsFeedback === 'object' && visitorsFeedback.main.hasOwnProperty('yes') ? (
                <div className="chart-container">
                  <ReactApexChart
                    type="donut"
                    series={[visitorsFeedback.main.yes, visitorsFeedback.main.no, /*visitorsFeedback.main.unknown*/]}
                    options={{
                      labels: ["Yes", "No", /*"Without an Answer"*/],
                      colors: ['#00E396','#FF4560','#FEB019']
                    }}
                  />
                </div>
              ) : ( <div className="vertCenter">Can't load the data.</div>)}

            </Col>

            <Col span={8}>
            
               <Divider><Title level={4}>Visitors' Most Useful Module</Title></Divider>
            
              {visitorsFeedback && typeof visitorsFeedback === 'object' && visitorsFeedback.main.hasOwnProperty('yes') ? (
                <div className="chart-container">
                  <ReactApexChart
                    type="donut"
                    series={Object.values(visitorsFeedback.section)}
                    options={{
                      labels: Object.keys(visitorsFeedback.section),
                      colors: ['#008FFB','#00E396','#FEB019']
                    }}
                  />
                </div>
              ) : ( <div className="vertCenter">Can't load the data.</div>)}

            </Col>
            <Col span={4}></Col>

          </Row>

          <Divider><Title level={4}>Raw Data</Title></Divider>

          <Table size="small" dataSource={visitorsStats} rowKey="key"
            columns={[
              {
                title: 'Visited [CET]',
                dataIndex: 'start_time',
                key: 'start_time',
                sorter:  (a, b) => a.start_time.localeCompare(b.start_time),
                defaultSortOrder: 'descent',
              },
              {
                title: 'Duration',
                key: 'difference',
                dataIndex: 'difference',
                render: value => value !== null ? printDuration(moment.duration(value)) : <span className="light">not recorded</span>,
                sorter:  (a, b) => moment.duration(a.difference).asSeconds() - moment.duration(b.difference).asSeconds()
              },
              {
                title: 'Visited sections',
                render: (record,index) => record.sections.length === 0 ? (
                  <span className="light">not recorded</span>
                ) : (
                  <ol>
                    {record.sections.map((e,i) => <li key={index+"-"+i}>{e}</li>)}
                  </ol>
                )
              },
              {
                title: 'Logged User',
                render: (record) => {
                  const user = allUsers.find(e => e.id === record.user_id)
                  return user ? user.name : ""
                },
                sorter:  (a, b) => {
                  let userA = allUsers.find(e => e.id === a.user_id)
                  userA = userA ? userA.name : ""
                  let userB = allUsers.find(e => e.id === b.user_id)
                  userB = userB ? userB.name : ""
                  return userA.localeCompare(userB)
                }
              },
              {
                title: 'IP',
                dataIndex: 'ip',
                key: 'ip',
                sorter:  (a, b) => a.ip.localeCompare(b.ip)
              },
              {
                title: 'Organization',
                dataIndex: 'organization',
                key: 'organization',
                sorter:  (a, b) => a.organization.localeCompare(b.organization)
              },
              {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                sorter:  (a, b) => a.city.localeCompare(b.city)
              },
              {
                title: 'Country',
                dataIndex: 'country',
                key: 'country',
                sorter:  (a, b) => a.country.localeCompare(b.country)
              },
              {
                title: 'Device Type',
                dataIndex: 'device',
                key: 'device',
                sorter:  (a, b) => a.device.localeCompare(b.device)
              },
              {
                title: 'OS',
                render: (record) => record.os.name + " " + record.os.version,
                sorter:  (a, b) => (a.os.name + " " + a.os.version).localeCompare((b.os.name + " " + b.os.version))
              },
              {
                title: 'Browser',
                render: (record) => record.browser.name + " " + record.browser.version,
                sorter:  (a, b) => (a.browser.name + " " + a.browser.version).localeCompare((b.browser.name + " " + b.browser.version))
              }
            ]}
          />

        </>)
      }
    </div>
  )
}

export default VisitorsStatistics
